<template>
  <div class="wrapper">
    <shrinkable-header ref="header" class="store-header">
      <p-header :fixed="false" type="flow"></p-header>
      <sub-header title="商家">
        <template v-slot:title>
          <div class="box">
            <img src="https://mktv-in-cdn.mockuai.com/16276674660155723.png" class="icon" />
            <span class="text">商家合作</span>
          </div>
        </template>
        <ul class="nav">
          <li class="nav-item" data-menuanchor="solution">
            <a href="/store#solution">商家解决方案</a>
          </li>
          <li class="nav-item" data-menuanchor="standard">
            <a href="/store#standard">合作标准</a>
          </li>
          <li class="nav-item" data-menuanchor="guide">
            <a href="/store#guide">入驻指南</a>
          </li>
          <li class="nav-item active">
            <a href="/source-factory">货源工厂</a>
          </li>
        </ul>
      </sub-header>
    </shrinkable-header>
    <div class="banner">
      <div class="banner-inner">
        <h1 class="title">魔筷X源头工厂-打造超级爆品</h1>
        <h2 class="sub-title">魔筷战略合作工厂-年产20亿gmv</h2>
        <div class="desc">你只管供货和生产，流量、订单、服务统统交给我们！</div>
      </div>
    </div>
    <div class="box1">
      <div class="title">魔筷合作主播</div>
      <div class="content">
        <div class="txt">全网头部主播带货，为产品背书/起势    数千位签约主播保证品牌产品持续曝光<br />30万中小主播为产品提供海量分发销售</div>
        <div class="user-list">
          <div class="user-item" v-for="user in userList1" :key="user.img">
            <img :src="user.img" class="avatar" alt="">
            <div class="user-name">{{ user.name }}</div>
            <div class="user-desc">{{ user.desc }}</div>
          </div>
        </div>
        <div class="user-list">
          <div class="user-item" v-for="user in userList2" :key="user.img">
            <img :src="user.img" class="avatar" alt="">
            <div class="user-name">{{ user.name }}</div>
            <div class="user-desc">{{ user.desc }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="box2">
      <div class="title">合作品牌与爆款打造</div>
      <img src="https://mktv-in-cdn.mockuai.com/16678704617184659.png" class="img1" alt="">
      <div class="goods-box">
        <img
          src="https://mktv-in-cdn.mockuai.com/16678776861117883.png"
          alt=""
          class="logo"
        />
        <div class="split"></div>
        <div class="goods-swiper">
          <!-- <swiper :options="goodsSwiperOption">
            <swiper-slide
              v-for="(item, index) in goodsSwiperData"
              :key="index"
            >
              <div class="good-item">
                <img :src="item.img" alt="">
              </div>
            </swiper-slide>
          </swiper> -->
          <div class="goods-list">
            <div
              v-for="(item, index) in displayGoods"
              :key="index"
              class="good-item"
            >
              <img :src="item.img" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box3">
      <div class="title">招商要求</div>
      <div class="list">
        <div class="item"><p>源头厂家</p></div>
        <div class="item"><p>食品类目<br />方便速食 休闲食品</p></div>
        <div class="item"><p>仅招募50家<br />魔筷战略合作厂家</p></div>
      </div>
      <div class="contact-us">
        <div class="contact-us-inner">
          <img src="https://mktv-in-cdn.mockuai.com/16678723290455987.jpg" class="qr" alt="">
          <div class="txt">有合作意向厂家  加企业微信回复：参加魔筷爆款工厂项目</div>
        </div>
        
      </div>
    </div>
    <div class="footer">
      <p-footer type="flow"></p-footer>
    </div>
  </div>
</template>

<script>
import ShrinkableHeader from '@/components/ShrinkableHeader.vue'
import SubHeader from '@/components/SubHeader.vue'
import Header from '@/layout/Header.vue'
import Footer from '@/layout/Footer.vue'

export default {
  head: {
    title: '魔筷源头工厂-战略合作工厂-魔筷星选',
    meta: [
      {
        name: 'Keywords',
        content:
          '魔筷源头工厂,魔筷战略合作工厂,',
      },
      {
        name: 'Description',
        content:
          '【魔筷科技-直播电商服务平台】提供直播电商供应链服务,聚焦“网红+电商”两端核心资源,依托直播流量,服务网红商家直播运营,持续为客户创造更多价值。',
      },
    ],
  },
  components: {
    'p-header': Header,
    SubHeader,
    ShrinkableHeader,
    'p-footer': Footer,
  },
  computed: {
    displayGoods() {
      return this.goodsSwiperData.concat(this.goodsSwiperData);
    },
  },
  data() {
    return {
      userList1: [
        {
          img: 'https://mktv-in-cdn.mockuai.com/16678708847551943.png',
          name: '恩恩爱爱爸爸',
          desc: '单视频播放1亿+'
        },
        {
          img: 'https://mktv-in-cdn.mockuai.com/16678708847546579.png',
          name: '婷婷不饿',
          desc: '单场185w+'
        },
        {
          img: 'https://mktv-in-cdn.mockuai.com/16678708847523303.png',
          name: '婉婉吖',
          desc: '粉丝144w+'
        },
        {
          img: 'https://mktv-in-cdn.mockuai.com/16678708847503267.png',
          name: '阿咪',
          desc: '粉丝515w+'
        },
       
      ],
      userList2: [
        {
          img: 'https://mktv-in-cdn.mockuai.com/16678708847486694.png',
          name: '阿里麻麻520',
          desc: '粉丝量500w+'
        },
        {
          img: 'https://mktv-in-cdn.mockuai.com/16678708847463942.png',
          name: '滕滕（包包）',
          desc: '粉丝65W+'
        },
        {
          img: 'https://mktv-in-cdn.mockuai.com/16678708847442319.png',
          name: '富妈妈金玲',
          desc: '月销300w+'
        },
        {
          img: 'https://mktv-in-cdn.mockuai.com/16678708847305313.png',
          name: '大表姐刘珠琰',
          desc: '场均50w+'
        },
      ],
      goodsSwiperData: [
        {
          img: "https://mktv-in-cdn.mockuai.com/16248591585182413.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248591585099839.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248591585045055.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248591585006300.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248591584619117.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248593337107330.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248593337078461.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248593337031088.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248593336932835.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248593336721408.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248594850206877.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248594850185517.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248594850165824.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248594850105240.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248594849854000.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248596560138053.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248596560116326.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248596560099309.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248596559778884.png",
        },

        {
          img: "https://mktv-in-cdn.mockuai.com/16248598781419990.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248598781376835.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248598781358593.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248598781303343.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248598781282352.png",
        },
        {
          img: "https://mktv-in-cdn.mockuai.com/16248598781068204.png",
        },
      ],
    }
  },
  mounted() {

  },

  methods: {
   
  },
}
</script>

<style lang="less" scoped>
@import '../style/common.less';

@minWidth: 1300px;

@keyframes rolling {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-50%);
  }
}
.wrapper {
  font-family: PingFangSC-Regular, PingFang SC;
  color: #FFF;
  text-align: ceter;
  padding-top: 150px;
}
.title {
  font-size: 36px;
  font-weight: 600;
  color: #000000;
  text-align: center;
}
.banner {
  width: 100%;
  padding-top: 56.6%;
  background-image: url(https://mktv-in-cdn.mockuai.com/16678734374745355.jpg?x-oss-process=image/quality,q_80);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  &-inner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    color: #FFF;
    text-align: center;
    .title {
      margin: 26% 0 30px;
      font-size: 72px;
      color: #FFF;
    }
    .sub-title {
      font-size: 36px;
      margin-bottom: 20px;
    }
    .desc {
      font-size: 21px;
      font-weight: 400;
      opacity: 0.8;
    }
  }
}
.box {
  display: flex;
  align-items: center;
  .icon {
    width: 28px;
    height: 28px;
    margin-right: 9px;
  }
  .text {
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FF6600;
  }
}
.box1 {
  width: 75%;
  min-width: @minWidth;
  text-align: center;
  margin: 0 auto;
  color: #FFF;
  .title {
    margin: 92px 0 57px;
  }
  .content {
    border-radius: 8px;
    background-image: url(https://mktv-in-cdn.mockuai.com/16678704617291237.jpg?x-oss-process=image/quality,q_80);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0 20px 40px;
    .txt {
      font-size: 30px;
      line-height: 42px;
      margin: 0 auto;
      padding: 50px 0 46px;
    }
    .user-list {
      display: flex;
      .user-item {
        flex: 1;
        margin: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px 0 20px;
        align-items: center;
        background: rgba(255,255,255,0.2);
        border-radius: 8px;
        border: 1px solid rgba(255,255,255,0.5);
        backdrop-filter: blur(8px);
      }
      .avatar {
        display: block;
        width: 62px;
        height: 62px;
      }
      .user-name {
        font-size: 20px;
        line-height: 28px;
        margin: 10px 0 5px 0;
      }
      .user-desc {
        font-size: 14px;
      }
    }
  }
}

.box2 {
  margin-top: 140px;
  background-image: url(https://mktv-in-cdn.mockuai.com/16678704617261533.jpg?x-oss-process=image/quality,q_80);
  background-size: cover;
  
  .title {
    padding: 104px 0 69px;
    text-align: center;
    color: #FFF;
  }

  .img1 {
    display: block;
    width: 75%;
    min-width: @minWidth;
    margin: 0 auto;
  }
}

.box3 {
  width: 75%;
  min-width: @minWidth;
  margin: 0 auto;
  .title {
    margin: 45px 0 35px;
  }
  .list {
    display: flex;
    .item {
      flex: 1;
      padding-top: 16%;
      color: #FFF;
      background-image: url(https://mktv-in-cdn.mockuai.com/16678704617219861.jpg?x-oss-process=image/quality,q_80);
      background-size: cover;
      margin-right: 40px;
      border-radius: 4px;
      position: relative;
      &:last-child {
        margin-right: 0;
      }
      p {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
      }
    }
  }
  .contact-us {
    background-image: url(https://mktv-in-cdn.mockuai.com/16678704617244100.jpg?x-oss-process=image/quality,q_80);
    background-size: cover;
    padding-top: 30%;
    position: relative;
    margin: 55px 0 80px;
    border-radius: 16px;
    &-inner {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .txt {
        font-size: 24px;
        margin-top: 35px;
        font-weight: 600;
      }
    }
  }
}

.goods-box {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 75%;
  min-width: @minWidth;
  padding: 55px 0;
  margin: 0 auto;
  .logo {
    .pxToVw(width,100);
    .pxToVw(height,28);
    display: block;
  }
  .split {
    width: 1px;
    height: 3.658536585365854vw;
    margin: 0 2.0833333333333335%;
    background-color: #fff;
    opacity: 0.5;
  }
  .goods-swiper {
    position: relative;
    flex: 1;
    width: 87.43055555555556%;
    height: 4.878048780487805vw;
    overflow: hidden;
    &::before,
    &::after {
      content: "";
      position: absolute;
      z-index: 20;
      .pxToVw(width, 48, 1640);
      height: 100%;
    }
    &::before {
      left: 0;
      background: linear-gradient(270deg, rgba(0, 9, 42, 0) 0%, #00092a 100%);
    }
    &::after {
      right: 0;
      background: linear-gradient(270deg, #00092a 0%, rgba(0, 9, 42, 0) 100%);
    }
    .swiper-wrapper {
      transition-timing-function: linear !important;
    }
    .good-item {
      width: 4.878048780487805vw;
      height: 4.878048780487805vw;
      margin-right: 1.951219512195122vw;
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .goods-list {
      animation: rolling 40s linear infinite;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      &.rolling {
        animation: rolling 27s linear infinite;
      }
      &:hover {
        animation-play-state: paused;
      }
    }
  }
}
</style>
